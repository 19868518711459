<template>
  <div class="brands">
    <div class="page-header">
      <h1>{{ $t('Brands Management') }} > {{ $t('Brands') }} > {{ $t('Edit') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Edit Brand') }}</h2>
      </div>
      <BrandForm @action="updateBrand"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Brand from '@/lib/brand';
import BrandForm from '@/components/brand/BrandForm.vue';

export default {
  name: 'EditBrand',
  components: {
    BrandForm
  },
  mounted(){
    this.loadBrand();
  },
  methods:{
    async loadBrand(){
      try{
        const loginInfo = Common.getLoginInfo();
        const brandId = this.$route.params.id;
        const brand = await Brand.loadBrand(this.apiUrl, brandId, loginInfo);
        this.setCurrentBrand(brand);
      }catch(err){
        console.log(err);
      }
    },
    async updateBrand(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const brandId = this.$route.params.id;
        const brandInfo = Object.assign({}, form);
        const brands = await Brand.updateBrand(this.apiUrl, brandId, brandInfo, loginInfo);
        this.$router.push('/brands');
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentBrand']),
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
